import {
  AppstoreAddOutlined,
  ExceptionOutlined,
  FundProjectionScreenOutlined,
  InsertRowAboveOutlined,
  SettingOutlined,
  ShopOutlined,
} from '@ant-design/icons';

const DASHBOARD = [
  {
    key: 'menu.dashboard',
    backendKey: 'sidenav.dashboard',
    label: 'Dashboard',
    icon: <FundProjectionScreenOutlined />,
    children: [
      {
        key: '/dashboard/sales',
        newKey: 'dashboard.sales',
        backendKey: 'sidenav.dashboard.sales',
        label: 'Penjualan',
      },
      {
        key: '/dashboard/prinsipal',
        newKey: 'dashboard.principal',
        backendKey: 'sidenav.dashboard.principal',
        label: 'Prinsipal',
      },
      {
        key: '/dashboard/daily-report',
        newKey: 'dashboard.dailyreport',
        backendKey: 'sidenav.dashboard.dailyreport',
        label: 'Laporan Harian',
      },
    ],
  },
];

const SYSTEM_MENU = [
  {
    key: 'menu.system',
    backendKey: 'sidenav.system',
    label: 'Sistem',
    icon: <SettingOutlined />,
    children: [
      {
        key: '/system/user',
        newKey: 'menu.system.setupuser',
        backendKey: 'sidenav.system.setupuser',
        label: 'Pengaturan Pengguna',
      },
      // {
      //   key: '/system/role',
      //   newKey: 'menu.system.role',
      //   backendKey: 'sidenav.system.role',
      //   label: 'Pengaturan Peran',
      // },
    ],
  },
];

const MASTER_MENU = [
  {
    key: 'menu.master',
    backendKey: 'sidenav.master',
    label: 'Master',
    icon: <AppstoreAddOutlined />,
    children: [
      {
        key: 'menu.master.branch',
        backendKey: 'sidenav.master.branch',
        label: 'Cabang',
      },
      {
        key: '/master/prinsipal',
        backendKey: 'sidenav.master.principal',
        label: 'Prinsipal',
      },
    ],
  },
];

const STOCK_MENU = [
  {
    key: 'menu.stock',
    backendKey: 'sidenav.stock',
    label: 'Manajemen Stok',
    icon: <InsertRowAboveOutlined />,
    children: [
      {
        key: '/stock/import-stock',
        backendKey: 'sidenav.stock.importstock',
        label: 'Impor Stok',
      },
    ],
  },
];

const SALES_MENU = [
  {
    key: 'menu.sales',
    backendKey: 'sidenav.sales',
    label: 'Penjualan',
    icon: <ShopOutlined />,
    children: [
      {
        key: '/sales/target-sales',
        backendKey: 'sidenav.sales.targetsales',
        label: 'Target Penjualan',
      },
      {
        key: '/sales/import-sales',
        backendKey: 'sidenav.sales.importsales',
        label: 'Impor Penjualan',
      },
    ],
  },
];

const REPORT_MENU = [
  {
    key: 'menu.report',
    backendKey: 'sidenav.report',
    label: 'Laporan',
    icon: <ExceptionOutlined />,
    children: [
      {
        key: '/report/branch',
        backendKey: 'sidenav.report.branch',
        label: 'Laporan Cabang',
      },
      {
        key: '/report/principal',
        backendKey: 'sidenav.report.principal',
        label: 'Laporan Prinsipal',
      },
      {
        key: '/report/salesman',
        backendKey: 'sidenav.report.salesman',
        label: 'Laporan Salesman',
      },
    ],
  },
];

export let MENU = [
  // ...DASHBOARD,
  ...SYSTEM_MENU,
  // ...MASTER_MENU,
  ...SALES_MENU,
  ...STOCK_MENU,
  ...REPORT_MENU,
];
