import {ConfigProvider, App as AppAntd} from 'antd';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import 'dayjs/locale/id';
import locale from 'antd/locale/id_ID';
import plugin from 'helpers/plugin';

import Router from './scenes/Router';
import {PRIMARY} from './constants/colors';

import DevelopmentBox from 'components/DevelopmentBox';

import './index.css';

let queryClient = new QueryClient();

function App() {
  plugin();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY,
        },
      }}
      button={{
        style: {fontWeight: 500},
      }}
      locale={locale}
    >
      <AppAntd>
        <QueryClientProvider client={queryClient}>
          <Router />
          <DevelopmentBox />
        </QueryClientProvider>
      </AppAntd>
    </ConfigProvider>
  );
}

export default App;
