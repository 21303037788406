import {useCallback, useMemo, useState} from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  message,
  Modal,
  Popover,
  Row,
  Spin,
  Table,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import {
  CloudUploadOutlined,
  FilterOutlined,
  FileOutlined,
  ImportOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {AlignType} from 'rc-table/lib/interface';
import dayjs from 'dayjs';

import {OwnedBranchDropdown} from 'components';

import {useQuery} from 'helpers/fetchingHooks';
import FetchInterceptor from 'helpers/fetchInterceptor';
import {countOffset} from 'helpers/globalHelpers';

import {TargetSalesTypes} from 'types';
import {printDownloadedFile} from 'helpers/printDownloadedFile';

const limit = 10;

export default function TargetSalesScenes() {
  let [form] = Form.useForm();
  let breakPoint = Grid.useBreakpoint();
  let isTablet = !breakPoint.lg;

  let [page, setPage] = useState<number>(1);
  let [file, setFile] = useState<UploadFile>();
  let [loading, setLoading] = useState(false);
  let [showFilter, setShowFilter] = useState(false);
  let [importVisible, setShowImport] = useState(false);

  let period = Form.useWatch('period', form);
  let branchId = Form.useWatch('branchId', form);

  let {data, isLoading, refetch} = useQuery<ResultPagination<TargetSalesTypes>>(
    {
      path: '/rms/target',
      queryKey: ['target-sales-scenes', {branchId, period, page}],
      config: {
        method: 'get',
        params: {
          limit,
          offset: countOffset(page, limit),
          ...(branchId ? {branchId} : {}),
          ...(period ? {date: dayjs(period).format('YYYY-MM-DD')} : {}),
        },
      },
    },
  );

  let targetSalesList = useMemo(() => data?.data?.rows ?? [], [data]);
  let targetSalesTotal = useMemo(() => data?.data?.count ?? 0, [data]);

  let onFilterClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  let openModalImport = useCallback(() => {
    setShowImport(true);
  }, []);

  let closeModalImport = useCallback(() => {
    setShowImport(false);
  }, []);

  let onImportSubmited = useCallback(async () => {
    let formValue;

    try {
      formValue = await form.validateFields();

      if (!file?.name) {
        message.warning('File tidak boleh kosong!');
        return;
      }
    } catch (error) {
      message.warning(`Form harus dilengkapi`);
      return;
    }

    try {
      let formData = new FormData();
      formData.append('file', file?.originFileObj as Blob);
      formData.append('date', dayjs(formValue?.date).format('YYYY-MM-DD'));

      await FetchInterceptor.post('/rms/target/import', formData);
      message.success('Data berhasil diimport');
      setShowImport(false);
      refetch();
    } catch (error) {}
  }, [file, refetch, form]);

  let onDownloadTemplate = useCallback(async () => {
    setLoading(true);
    try {
      await printDownloadedFile({
        path: '/rms/target/export',
        type: 'xlsx',
        nameFile: `target-sales-import-template`,
      });

      setShowImport(false);
    } catch (error) {}
    setLoading(false);
  }, []);

  const column = [
    {
      title: 'No',
      width: 55,
      render: (_: string, __: TargetSalesTypes, index: number) =>
        index + countOffset(page, limit) + 1,
    },
    {
      title: 'Cabang',
      render: (data: TargetSalesTypes) => data?.branch ?? '-',
    },
    {
      title: 'RBM',
      render: (data: TargetSalesTypes) => data?.rbm ?? '-',
    },
    {
      title: 'HOC',
      render: (data: TargetSalesTypes) => data?.hoc ?? '-',
    },
    {
      title: 'Prinsipal',
      render: (data: TargetSalesTypes) => data?.principal ?? '-',
    },
    {
      title: 'Target',
      align: 'end' as AlignType,
      render: (data: TargetSalesTypes) => {
        let number = Number(data?.target).toLocaleString('id-ID').split(',');

        return (
          <Row justify="space-between">
            <Col>Rp.</Col>
            <Row>
              <Col>{number[0]}</Col>
              <Col>{number[1] ? `,${number[1]}` : ',--'}</Col>
            </Row>
          </Row>
        );
      },
    },
  ];

  let formLoading = loading || isLoading;

  return (
    <Card className="mt15">
      <Row align="middle" gutter={10} className="row-card-container">
        <Col flex={10}>
          <Typography.Text className="title">Target Penjualan</Typography.Text>
        </Col>
        <Col flex={1}>
          <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
            <Col>
              <Button
                key="import"
                icon={<ImportOutlined />}
                onClick={openModalImport}
              />
            </Col>
            <Col>
              <Popover
                content={
                  <Form layout="vertical" form={form}>
                    <Row gutter={16} align="middle" justify="space-between">
                      <Col span={24}>
                        <Form.Item label="Periode" name="period">
                          <DatePicker.MonthPicker
                            className="w-100p"
                            format="MMMM YYYY"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Cabang" name="branchId">
                          <OwnedBranchDropdown />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="divider-filter-action" />
                    <Row justify="end" gutter={10}>
                      <Col>
                        <Button onClick={onFilterClear}>Bersih</Button>
                      </Col>
                      <Col>
                        <Button type="primary">Terapkan</Button>
                      </Col>
                    </Row>
                  </Form>
                }
                placement="bottomRight"
                arrow={false}
                title={
                  <>
                    <Row>
                      <FilterOutlined className="mr5" />
                      Filter
                    </Row>
                    <Divider className="mv5" />
                  </>
                }
                trigger="click"
                open={showFilter}
                onOpenChange={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <Button icon={<FilterOutlined />} />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        columns={column}
        dataSource={targetSalesList}
        loading={formLoading}
        rowKey="id"
        {...(isTablet ? {scroll: {y: 300, x: 1000}} : {scroll: {y: '57vh'}})}
        pagination={{
          current: page,
          onChange: setPage,
          total: targetSalesTotal,
          showTotal: (total: number, range) => (
            <div className="total-pagination">
              Menampilkan {range[0]} - {range[1]} dari {total}
            </div>
          ),
        }}
      />

      <Modal
        title="Import Target Penjualan"
        open={importVisible}
        onCancel={closeModalImport}
        footer={
          <Row justify="space-between" gutter={[5, 5]}>
            <Col xs={24} lg={{span: 8, offset: 4, pull: 4}}>
              <Button
                loading={formLoading}
                onClick={onDownloadTemplate}
                block
                icon={<FileOutlined />}
              >
                Unduh Templat
              </Button>
            </Col>
            <Col xs={24} lg={12}>
              <Row gutter={5}>
                <Col xs={12}>
                  <Button
                    loading={formLoading}
                    onClick={closeModalImport}
                    block
                  >
                    Kembali
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    loading={formLoading}
                    onClick={onImportSubmited}
                    type="primary"
                    icon={<SaveOutlined />}
                    block
                  >
                    Simpan
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      >
        <Divider className="mv10" />
        <Spin size="large" spinning={formLoading}>
          <Form layout="vertical" form={form}>
            <Row align="bottom">
              <Col span={24} md={12}>
                <Form.Item
                  name="date"
                  label="Periode"
                  rules={[
                    {
                      required: true,
                      message: 'Periode harus diisi!',
                    },
                  ]}
                >
                  <DatePicker
                    picker="month"
                    format="MMMM YYYY"
                    className="w-100p"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Upload.Dragger
              name="file"
              accept=".csv .xls .xlsx"
              maxCount={1}
              beforeUpload={() => false}
              onRemove={() => setFile(undefined)}
              onChange={({file, fileList}) => {
                if (file.status !== 'removed') {
                  setFile(fileList[0]);
                } else {
                  setFile(undefined);
                }
              }}
            >
              <p className="ant-upload-drag-icon mb-2">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text m-0">
                Pilih file atau letakkan file Excel-mu di sini.
              </p>
              <p className="ant-upload-hint mb-2">
                Jenis file hanya berupa excel
              </p>
              <Button type="primary" size="small">
                Pilih File
              </Button>
            </Upload.Dragger>
          </Form>
        </Spin>
      </Modal>
    </Card>
  );
}
