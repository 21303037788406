import {Navigate} from 'react-router-dom';

import {PrincipalReportScenes} from './PrincipalReportScenes';

export const FEATURE_PATH = '/report/principal';

export const reportPrincipalRouter = [
  {
    path: FEATURE_PATH,
    backendKey: 'sidenav.report.principal',
    element: <PrincipalReportScenes />,
  },
  {
    path: `${FEATURE_PATH}/*`,
    backendKey: 'sidenav.report.principal',
    element: <Navigate to={FEATURE_PATH} replace />,
  },
];
