import {useCallback} from 'react';
import {Select, SelectProps} from 'antd';
import {BaseOptionType} from 'antd/es/select';

import {useGetUserData} from 'helpers/useGetUserData';

export function OwnedBranchDropdown(props: SelectProps) {
  let {branches} = useGetUserData();

  let filterSort = useCallback(
    (optionA: BaseOptionType, optionB: BaseOptionType) =>
      String(optionA?.label ?? '')
        .toLowerCase()
        .localeCompare(String(optionB?.label ?? '').toLowerCase()),
    [],
  );

  return (
    <Select
      placeholder="Masukan Cabang"
      filterSort={filterSort}
      optionFilterProp="label"
      showSearch
      {...props}
    >
      {branches.map((item) => (
        <Select.Option
          value={item.id}
          key={`${item.id}-owned-branch-dropdown`}
          label={item.name}
        >
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
