import {Navigate} from 'react-router-dom';

import {UserListScenes} from './UserListScenes';
import {UserDetailScenes} from './UserDetailScenes';

export const FEATURE_PATH = '/system/user';

export const userRouter = [
  {
    path: FEATURE_PATH,
    backendKey: 'sidenav.system.setupuser',
    element: <UserListScenes />,
  },
  {
    path: `${FEATURE_PATH}/add`,
    backendKey: 'sidenav.system.setupuser',
    element: <UserDetailScenes />,
  },
  {
    path: `${FEATURE_PATH}/view/:id`,
    backendKey: 'sidenav.system.setupuser',
    element: <UserDetailScenes />,
  },
  {
    path: `${FEATURE_PATH}/detail/:id`,
    backendKey: 'sidenav.system.setupuser',
    element: <UserDetailScenes />,
  },
  {
    path: `${FEATURE_PATH}/edit/:id`,
    backendKey: 'sidenav.system.setupuser',
    element: <UserDetailScenes />,
  },
  {
    path: `${FEATURE_PATH}/*`,
    backendKey: 'sidenav.system.setupuser',
    element: <Navigate to="/users" replace />,
  },
];
