export const VALIDATION_REGEX = {
  username: /^[a-zA-Z0-9 -\s]+$/,
  description: /^[a-zA-Z0-9 -\s]+$/,
  email:
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  latitude: /^-?([0-8]?[0-9]|90)(\.[0-9]{1,20})?$/,
  longitude: /^-?([0-9][0-9]|1[0-9][0-9]|180)(\.[0-9]{1,20})?$/,
  latLong: /^-?([0-9][0-9]|1[0-9][0-9]|180)(\.[0-9]{1,10})?$/,
  name: /^[A-Z a-z .]+$/,
  phoneNumber: /^[0-9]+$/,
  branch: /^[a-zA-Z0-9 -\s]+$/,
  supplierCode: /^[a-zA-Z0-9 -\s]+$/,
  percentage: /^[0-9 .]+$/,
};
