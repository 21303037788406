import {ReactNode} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import AuthenticatedLayout from 'components/layout/AuthenticatedLayout';
import AuthenticationScene from './authentication/AuthenticationScene';
import HomeScene from './home/HomeScenes';

import {useGetUserData} from 'helpers/useGetUserData';

import {allRouter} from './index';

type RouterType = {
  backendKey: string;
  path: string;
  element: ReactNode;
};

export default function Router() {
  let {employeeId, accessMenus, token} = useGetUserData();

  // TODO : Enhance this, check to the backend is token valid
  let isAuthenticated = !!token && !!employeeId;

  let filteredRouter = allRouter.reduce(
    (prev: Array<ReactNode>, cur: RouterType) => {
      if (accessMenus.some((item) => item === cur.backendKey)) {
        return [
          ...prev,
          <Route
            path={cur.path}
            element={cur.element}
            key={`router-${cur.path}`}
          />,
        ];
      }

      return prev;
    },
    [],
  );

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <Routes>
          <Route element={<AuthenticatedLayout />}>
            <Route path="/" element={<HomeScene />} />
            {filteredRouter}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<AuthenticationScene />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
