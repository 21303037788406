import {Navigate} from 'react-router-dom';

import TargetSalesScenes from './TargetSalesScenes';

export const FEATURE_PATH = '/sales/target-sales';

export const targetSalesRouter = [
  {
    path: FEATURE_PATH,
    backendKey: 'sidenav.sales.targetsales',
    element: <TargetSalesScenes />,
  },
  {
    path: `${FEATURE_PATH}/*`,
    backendKey: 'sidenav.sales.targetsales',
    element: <Navigate to={FEATURE_PATH} replace />,
  },
];
