import {ReactElement, CSSProperties} from 'react';
import {Typography, Avatar, Col, Card} from 'antd';

export default function HomeScene() {
  return (
    <Col style={styles.container}>
      <Col>
        <div>
          <Typography.Title className="m0">Selamat Datang</Typography.Title>
        </div>
        <div>
          <Typography.Text>
            Di Distribution Management System RMS
          </Typography.Text>
        </div>
      </Col>
      {/* <Row style={{marginTop: 50}} align="middle">
        <Col span={12}>
          <CardBox
            title="Title 1"
            subtitle="Subtitle 1"
            icon={<InfoCircleOutlined />}
            iconColor="#706bff"
            shadowColor="#706bff91"
          />
          <CardBox
            title="Title 2"
            subtitle="Subtitle 2"
            icon={<FullscreenExitOutlined />}
            iconColor="#2a8807"
            shadowColor="#2a880787"
          />
          <CardBox
            title="Title 3"
            subtitle="Subtitle 3"
            icon={<BgColorsOutlined />}
            iconColor="#f29b28"
            shadowColor="#f29b28b3"
          />
          <CardBox
            title="Title 4"
            subtitle="Subtitle 4"
            icon={<LineChartOutlined />}
            iconColor="#b428f2"
            shadowColor="#b428f299"
          />
        </Col>
        <Col span={12}>
          <Image src="/svg/home.svg" preview={false} />
        </Col>
      </Row> */}
    </Col>
  );
}

type Props = {
  title: string;
  subtitle: string;
  icon: ReactElement;
  iconColor: string;
  shadowColor: string;
};

function CardBox({title, subtitle, icon, iconColor, shadowColor}: Props) {
  return (
    <Card style={styles.cardBoxContainer}>
      <div style={styles.cardBoxWrapper}>
        <Avatar
          shape="square"
          icon={icon}
          style={{
            backgroundColor: iconColor,
            boxShadow: `0px 0px 15px 6px ${shadowColor}`,
            marginRight: 20,
          }}
        />
        <div style={styles.column}>
          <Typography.Text strong>{title}</Typography.Text>
          <Typography.Text>{subtitle}</Typography.Text>
        </div>
      </div>
    </Card>
  );
}

const styles: ObjectKey<CSSProperties> = {
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardBoxContainer: {
    marginBottom: 20,
  },
  cardBoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
  },
};
