import {CSSProperties} from 'react';
import {IS_DEVELOPMENT} from 'constants/globalConstants';

const DevelopmentBox = () => {
  if (IS_DEVELOPMENT) {
    return <div style={styles.container}>Development Mode</div>;
  }

  return null;
};

const styles: ObjectKey<CSSProperties> = {
  container: {
    background: '#707070',
    color: '#f4f4f4',
    opacity: 0.7,
    textAlign: 'center',
    padding: '1px',
    bottom: 0,
    right: 0,
    position: 'fixed',
    zIndex: 99,
    width: '100%',
    fontSize: 8,
    userSelect: 'none',
  },
};

export default DevelopmentBox;
