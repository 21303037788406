import {Select, SelectProps} from 'antd';

export const ROLE_OPTIONS = [
  {value: '0964b011-9345-4884-9dbe-9fb56f915522', label: 'HO'},
  {value: 'b06f8668-79d0-4057-8ce6-68ceba949a2f', label: 'HOC'},
  {value: '00da7f14-7465-4140-bc0c-1789bc0fe183', label: 'RBM'},
  {value: '5d30753e-559a-4597-b318-873ba0a602da', label: 'Admin Cabang'},
];

export function RoleDropdown(props: SelectProps) {
  return (
    <Select placeholder="Hak Akses" {...props}>
      {ROLE_OPTIONS.map((item) => (
        <Select.Option value={item.value} key={`${item.value}-role-dropdown`}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}
