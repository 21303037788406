import {useCallback, useEffect, useState} from 'react';
import {
  App,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import {
  EditOutlined,
  LeftOutlined,
  LockOutlined,
  SaveOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {OwnedBranchDropdown, RoleDropdown} from 'components';

import {useMutation, useQuery} from 'helpers/fetchingHooks';

import {VALIDATION_REGEX} from 'constants/validation';

import {FEATURE_PATH} from '.';
import type {User} from 'types';

const {Text} = Typography;

export function UserDetailScenes() {
  let [form] = Form.useForm();
  let {message} = App.useApp();
  let navigate = useNavigate();
  let {id} = useParams();
  let {pathname} = useLocation();

  let mode = pathname.replace(FEATURE_PATH, '').split('/')[1];

  let [useDefaultPassowrd, setUseDefaultPassowrd] = useState(false);

  let isAdd = mode === 'add';
  let isView = mode === 'view';

  let {data, isLoading} = useQuery<ResultDetail<User>>({
    path: `/rms/employee/${id}`,
    config: {
      params: {},
    },
    configRQ: {
      queryKey: ['user-detail-scenes', {}],
      enabled: !isAdd,
    },
  });

  let {isPending, mutate: onSubmit} = useMutation(
    {
      path: `/rms/employee${isAdd ? '' : `/${id}`}`,
      method: isAdd ? 'post' : 'put',
    },
    {
      onSuccess: () => {
        message.success(`Data berhasil ${isAdd ? 'ditambah' : 'diperbarui'}`);
        navigate(FEATURE_PATH);
      },
    },
  );

  useEffect(() => {
    if (data) {
      let formData = {
        name: data?.data.name,
        branchId: data?.data?.branch?.map((item) => item.id) ?? [],
        email: data?.data?.email,
        roleId: data?.data?.role_id,
        isActive: data?.data?.is_active,
      };
      form.setFieldsValue(formData);
    }
  }, [data, form]);

  let onSubmitPressed = useCallback(async () => {
    let formValue;
    try {
      formValue = await form.validateFields();
    } catch (error) {
      message.error('Form harus dilengkapi');
      return;
    }

    try {
      onSubmit({...formValue, isActive: formValue?.isActive ?? false});
    } catch (error) {}
  }, [form, message, onSubmit]);

  let backHandler = useCallback(() => {
    navigate(FEATURE_PATH);
  }, [navigate]);

  let onEdithandler = useCallback(
    (mode: 'edit') => () => {
      navigate(`${FEATURE_PATH}/${mode}/${id}`);
    },
    [navigate, id],
  );

  let onSetDefaultPasswordPressed = useCallback(() => {
    form.setFieldsValue({
      password: useDefaultPassowrd ? '' : '123456',
    });
    if (useDefaultPassowrd) {
      setUseDefaultPassowrd(false);
    } else {
      setUseDefaultPassowrd(true);
    }
  }, [form, useDefaultPassowrd]);

  let formLoading = isLoading || isPending;
  let formDisable = isView;

  return (
    <Card className="mt15">
      <Row align="middle" gutter={[10, 10]} className="row-card-container">
        <Col flex={10}>
          <Row align="middle">
            <Button
              icon={<LeftOutlined style={styles.backIcon} />}
              type="text"
              onClick={backHandler}
              loading={formLoading}
            />
            <Text className="title">Detail Pengguna</Text>
          </Row>
        </Col>
        {isView ? (
          <Col flex={1}>
            <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
              <Col>
                <Button
                  icon={<EditOutlined />}
                  onClick={onEdithandler('edit')}
                  loading={formLoading}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          <Col flex={1}>
            <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
              <Col>
                <Button
                  icon={<SaveOutlined />}
                  onClick={onSubmitPressed}
                  loading={formLoading}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Divider />
      <Spin spinning={formLoading}>
        <Form form={form} layout="vertical" disabled={formDisable}>
          <Row gutter={16}>
            <Col span={24} md={6}>
              <Form.Item
                label="Nama"
                name="name"
                rules={[
                  {required: true, message: 'Nama harus diisi!'},
                  {
                    pattern: new RegExp(VALIDATION_REGEX.name),
                    message: 'Format Nama salah',
                  },
                ]}
              >
                <Input placeholder="Masukan nama" />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label="Cabang"
                name="branchId"
                rules={[{required: true, message: 'Cabang harus dipilih!'}]}
              >
                <OwnedBranchDropdown mode="multiple" />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                name="email"
                label="Username"
                rules={[
                  {required: true, message: 'Username harus diisi!'},
                  {
                    pattern: new RegExp(VALIDATION_REGEX.username),
                    message: 'Format username salah',
                  },
                ]}
              >
                <Input placeholder="Masukan Email" type="text" />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label="Password"
                name="password"
                {...(!isAdd
                  ? {}
                  : {
                      rules: [
                        {required: true, message: 'Password harus diisi'},
                      ],
                    })}
              >
                <Input
                  placeholder="Masukan password"
                  readOnly={useDefaultPassowrd}
                  addonAfter={
                    <Tooltip title="password default">
                      <div
                        style={{userSelect: 'none'}}
                        onClick={onSetDefaultPasswordPressed}
                      >
                        {useDefaultPassowrd ? (
                          <LockOutlined />
                        ) : (
                          <UnlockOutlined />
                        )}
                      </div>
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label="Hak Akses"
                name="roleId"
                rules={[{required: true, message: 'Hak akses harus dipilih'}]}
              >
                <RoleDropdown />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item label="Status" name="isActive" valuePropName="checked">
                <Switch
                  checkedChildren={<Text className="txt-white">Aktif</Text>}
                  unCheckedChildren={<Text>Inaktif</Text>}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
}

const styles = {
  backIcon: {fontSize: 20, cursor: 'pointer'},
};
