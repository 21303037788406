import {CSSProperties} from 'react';
import {Outlet} from 'react-router-dom';
import {Layout} from 'antd';

import Menu from '../menu/Menu';

export default function AuthenticatedLayout() {
  return (
    <Layout style={styles.container}>
      <Menu />
      <Layout style={styles.content}>
        <Outlet />
      </Layout>
    </Layout>
  );
}

const styles: ObjectKey<CSSProperties> = {
  container: {height: '100vh'},
  content: {
    height: '100vh',
    overflow: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
    zIndex: 1,
    backgroundColor: '#a8c7fa1c',
  },
};
