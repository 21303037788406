import {Col, Form, Input, Popover, Select} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {SelectProps} from 'antd/lib';

import {useCallback, useMemo, useState} from 'react';
import {useGetUserData} from 'helpers/useGetUserData';

type Props = SelectProps & {
  value?: string;
  showOptionAllZone?: boolean;
  onChange?: (value: string) => void;
  type?: 'dropdown' | 'popover';
};

type Option = {value: string; label: string};

export function ZoneDropdown(props: Props) {
  let {onChange, value, type = 'dropdown', showOptionAllZone = false} = props;
  let [form] = Form.useForm();
  let {branches} = useGetUserData();

  let zoneList = useMemo(() => {
    let data =
      branches?.reduce((prev: Option[], cur) => {
        if (prev.some((item: Option) => item.value === cur.region)) {
          return prev;
        }

        return [...prev, {value: cur?.region ?? '', label: cur?.region ?? ''}];
      }, []) ?? [];

    if (showOptionAllZone) {
      return [{value: 'all', label: 'Semua Zona'}, ...data];
    } else {
      return data;
    }
  }, [branches, showOptionAllZone]);

  let searchValue = Form.useWatch('search', form);

  let [showFilter, setShowFilter] = useState(false);

  let onOptionPressed = useCallback(
    (value: string) => () => {
      onChange && onChange(value);
      setShowFilter(false);
      form.resetFields();
    },
    [form, onChange],
  );

  if (type === 'dropdown') {
    return (
      <Select placeholder="Masukan Zona" {...props}>
        {zoneList.map((item) => (
          <Select.Option value={item.value} key={`${item.value}-zone-dropdown`}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <Popover
      content={
        <Form layout="vertical" form={form}>
          <Col>
            <Form.Item name="search" className="mb0">
              <Input
                prefix={<SearchOutlined />}
                placeholder="Cari..."
                allowClear
              />
            </Form.Item>
            <div style={{paddingTop: 5}}>
              {zoneList.reduce((prev: any, cur, index) => {
                if (
                  searchValue &&
                  !cur.label.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                  return prev;
                }

                return [
                  ...prev,
                  <div
                    key={`zone-dropdown-${index}`}
                    className="text-option"
                    onClick={onOptionPressed(cur.value)}
                  >
                    {cur.label}
                  </div>,
                ];
              }, [])}
            </div>
          </Col>
        </Form>
      }
      placement="bottomRight"
      arrow={false}
      trigger="click"
      open={showFilter}
      onOpenChange={() => {
        setShowFilter(!showFilter);
      }}
    >
      <b style={{cursor: 'pointer'}}>
        {value
          ? zoneList.find((item) => item.value === value)?.label
          : 'Pilih Zona'}
      </b>
    </Popover>
  );
}
