import {CSSProperties, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Drawer, Image, Layout, Row, Menu as MenuAntd} from 'antd';
import {MenuOutlined, LeftOutlined} from '@ant-design/icons';

import {useGetUserData} from 'helpers/useGetUserData';
import {checkAvaibilityMenu} from 'helpers/globalHelpers';

import {MENU} from 'fixtures/menu';
import {WHITE} from 'constants/colors';

import NavProfile from './NavProfile';

export default function Menu() {
  let {accessMenus} = useGetUserData();
  let navigate = useNavigate();

  let [collapsed, setCollapsed] = useState<boolean>(false);

  let filteredMenu = checkAvaibilityMenu<MenuStructureType>(MENU, accessMenus);

  let onCollapsedPressed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  let onMenuClose = useCallback(() => {
    setCollapsed(false);
  }, []);

  let onMenuClick = useCallback(
    ({key}: {key: string}) =>
      () => {
        navigate(key);
        onCollapsedPressed();
      },
    [navigate, onCollapsedPressed],
  );

  let TitleDrawer = () => {
    return (
      <Row align="middle" gutter={16} style={styles.titleDrawer}>
        <Col>
          <div style={styles.iconBack} onClick={onMenuClose}>
            <LeftOutlined />
          </div>
        </Col>
        <Col>
          <a onClick={onMenuClick({key: '/dashboard'})}>
            <Image
              width={80}
              src="/logo.png"
              alt="login-logo"
              preview={false}
            />
          </a>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Layout.Header style={styles.container}>
        <Row gutter={16} align="middle">
          <Col className="mr10">
            <Button
              onClick={onCollapsedPressed}
              icon={<MenuOutlined />}
              type="default"
            />
          </Col>
          <a onClick={onMenuClick({key: '/dashboard'})}>
            <Image
              src="/logo.png"
              alt="login-logo"
              preview={false}
              width={80}
            />
          </a>
        </Row>
        <Row gutter={16}>
          <Col>
            <NavProfile />
          </Col>
        </Row>
      </Layout.Header>
      <Drawer
        title={<TitleDrawer />}
        placement="left"
        closable={false}
        onClose={onCollapsedPressed}
        open={collapsed}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {/* <MenuAntd mode="inline" items={filteredMenu} onClick={onMenuClick} /> */}
        <MenuAntd
          mode="inline"
          items={filteredMenu}
          onClick={({key}) => {
            navigate(key);
            onCollapsedPressed();
          }}
        />
      </Drawer>
    </>
  );
}

const styles: ObjectKey<CSSProperties> = {
  container: {
    backgroundColor: WHITE,
    height: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
  },
  titleDrawer: {
    height: 45,
    display: 'flex',
  },
  iconBack: {
    cursor: 'pointer',
    fontSize: 16,
  },
};
