import {
  useMutation as useMutationRQ,
  UseMutationOptions,
  useQuery as useQueryRQ,
  UseQueryOptions,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query';
import {AxiosRequestConfig, AxiosResponse} from 'axios';

import FetchInterceptor from './fetchInterceptor';

type AxiosProps = {
  path: string;
  method: 'post' | 'put' | 'patch' | 'delete';
  config?: AxiosRequestConfig<any>;
};

type UseQueryConfig<T> = Omit<UseQueryOptions<T>, 'initialData'> & {
  initialData?: () => undefined;
};

export function useMutation<D, R = any, E = any>(
  axiosProps: AxiosProps,
  options?: UseMutationOptions<AxiosResponse<R> | undefined, E, D, any>,
) {
  let {method, config, path} = axiosProps;

  return useMutationRQ({
    mutationFn: async (data) => {
      // @ts-ignore
      let exactPath = `${path}${data?.anotherParams ?? ''}`;

      if (method === 'post') {
        return await FetchInterceptor.post(exactPath, data, config);
      } else if (method === 'put') {
        return await FetchInterceptor.put(exactPath, data, config);
      } else if (method === 'patch') {
        return await FetchInterceptor.patch(exactPath, data, config);
      } else if (method === 'delete') {
        return await FetchInterceptor.delete(exactPath, config);
      }
    },
    ...options,
  });
}

type UseQueryProps<T> = {
  path: string;
  config?: AxiosRequestConfig<any>;
  configRQ?: UseQueryConfig<T>;
  queryKey?: [string, any];
  queryFn?: QueryFunction<T, QueryKey>;
};

export function useQuery<T>(props: UseQueryProps<T>) {
  let {path, config, configRQ, queryKey, queryFn} = props;

  return useQueryRQ<T, any>({
    queryKey: queryKey ? queryKey : [path, config],
    queryFn: queryFn
      ? queryFn
      : async () => {
          return await FetchInterceptor.get(path, config);
        },
    ...(configRQ ? configRQ : {}),
  });
}
