import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Grid,
  Input,
  Modal,
  Row,
  Segmented,
  Table,
  Typography,
} from 'antd';
import {
  ExportOutlined,
  LeftOutlined,
  MoreOutlined,
  NodeIndexOutlined,
  RadarChartOutlined,
  SaveOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import dayjs, {Dayjs} from 'dayjs';

import {ZoneDropdown, TextDatePicker, BranchDropdown} from 'components';

import {useQuery} from 'helpers/fetchingHooks';
import {printDownloadedFile} from 'helpers/printDownloadedFile';
import {useGetUserData} from 'helpers/useGetUserData';
import {countOffset} from 'helpers/globalHelpers';

import type {User, ReportPrincipalBudgetType, ReportTargetType} from 'types';

const {Text} = Typography;
const limit = 10;

export function SalesReportScenes() {
  let [form] = Form.useForm();
  let {useZone} = useGetUserData();
  let breakPoint = Grid.useBreakpoint();
  let isTablet = !breakPoint.lg;

  let [page, setPage] = useState(1);
  let [showModalExport, setShowModalExport] = useState(false);
  let [tableCategory, setTableCategory] = useState('Budget');
  let [selectedZone, setSelectedZone] = useState('');
  let [selectedBranch, setSelectedBranch] = useState('');
  let [selectedPeriode, setSelectedPeriode] =
    useState<Optional<Dayjs>>(dayjs());

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [selectedZone, selectedBranch, selectedPeriode]);

  let {data, isLoading} = useQuery<ResultDetail<ReportTargetType>>({
    path: '/rms/dashboard/target',
    queryKey: [
      'dashboard-target',
      {selectedPeriode, selectedZone, selectedBranch},
    ],
    config: {
      params: {
        ...(selectedPeriode
          ? {date: selectedPeriode.format('YYYY-MM-DD')}
          : {}),
        ...(selectedZone && selectedZone !== 'all' ? {zone: selectedZone} : {}),
        ...(selectedBranch && selectedBranch !== 'all'
          ? {branchId: selectedBranch}
          : {}),
      },
    },
    configRQ: {
      queryKey: [
        'dashboard-target',
        {selectedPeriode, selectedZone, selectedBranch},
      ],
      enabled: !!selectedZone && !!selectedPeriode,
    },
  });

  let {data: salesListData, isLoading: salesListLoading} = useQuery<
    ResultPagination<ReportPrincipalBudgetType>
  >({
    path: `/rms/dashboard/sales/${tableCategory.toLowerCase()}`,
    queryKey: [
      'dashboard-sales',
      {selectedPeriode, selectedZone, tableCategory, selectedBranch, page},
    ],
    config: {
      params: {
        limit,
        offset: countOffset(page, limit),
        ...(selectedPeriode
          ? {date: selectedPeriode.format('YYYY-MM-DD')}
          : {}),
        ...(selectedZone && selectedZone !== 'all' ? {zone: selectedZone} : {}),
        ...(selectedBranch && selectedBranch !== 'all'
          ? {branchId: selectedBranch}
          : {}),
      },
    },
    configRQ: {
      queryKey: [
        'dashboard-sales',
        {selectedPeriode, selectedZone, tableCategory, selectedBranch, page},
      ],
      enabled: !!selectedZone && !!selectedPeriode,
    },
  });

  let onModalExportClosed = useCallback(() => setShowModalExport(false), []);
  let onModalExportOpened = useCallback(() => setShowModalExport(true), []);
  let onExportSubmited = useCallback(async () => {
    let formValue;
    try {
      formValue = await form.validateFields();
    } catch (error) {}

    try {
      let date = dayjs(formValue?.period).format('YYYY-MM-DD');

      await printDownloadedFile({
        path: '/rms/dashboard/report',
        params: {
          date,
          reportType: 'Laporan Effective Call Salesman',
        },
        type: 'xlsx',
        nameFile: `laporan-effective-call-salesman-${date}`,
      });

      setShowModalExport(false);
    } catch (error) {}
  }, [form]);

  let listData = useMemo(() => {
    let rawData =
      tableCategory === 'Call'
        ? ((salesListData?.data as any)?.data?.rows ?? [])
        : (salesListData?.data?.rows ?? []);

    return (rawData as [Object]).map((item, index) => ({id: index, ...item}));
  }, [tableCategory, salesListData]);
  let listTotal = useMemo(
    () =>
      tableCategory === 'Call'
        ? ((salesListData?.data as any)?.data?.count ?? 0)
        : (salesListData?.data?.count ?? 0),
    [tableCategory, salesListData],
  );

  const column = [
    {
      title: 'No',
      width: 55,
      render: (_: string, __: User, index: number) =>
        index + countOffset(page, limit) + 1,
    },
    {
      title: 'Zona',
      dataIndex: 'zone',
    },
    {
      title: 'Cabang',
      dataIndex: 'branch',
    },
    {
      title: 'RBM',
      dataIndex: 'rbm',
    },
    ...(tableCategory === 'Call'
      ? [
          {
            title: 'Kode Salesman',
            dataIndex: 'salesman_code',
          },
          {
            title: 'Salesman',
            dataIndex: 'salesman_name',
          },
        ]
      : []),
    {
      title: 'Sellout',
      dataIndex: 'sellout',
      render: (sellout: number) => {
        return (
          <Row justify="space-between">
            <Col>Rp.</Col>
            <Col>{Number(sellout).toLocaleString('id')}</Col>
          </Row>
        );
      },
    },
    ...(tableCategory === 'Budget'
      ? [
          {
            title: 'Budget Internal',
            dataIndex: 'budget_internal',
            render: (internalBudget: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(internalBudget).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: '% Usage',
            dataIndex: 'usage_budget_internal',
            render: (usage: number) => {
              return (
                <Row justify="end">
                  <Col>{usage}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
          {
            title: 'Budget Claim',
            dataIndex: 'budget_external',
            render: (budgetClaim: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(budgetClaim).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: '% Usage',
            dataIndex: 'usage_budget_external',
            render: (usage: number) => {
              return (
                <Row justify="end">
                  <Col>{usage}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
          {
            title: 'Total Budget',
            dataIndex: 'total_budget',
            render: (budgetClaim: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(budgetClaim).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: '% Usage',
            dataIndex: 'usage_total_budget',
            render: (usage: number) => {
              return (
                <Row justify="end">
                  <Col>{usage}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
        ]
      : []),
    ...(tableCategory === 'Return'
      ? [
          {
            title: 'Return Standar',
            dataIndex: 'return_standard',
            render: (returnStandar: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(returnStandar).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: '%',
            dataIndex: 'percentage_return_standard',
            render: (ach: number) => {
              return (
                <Row justify="end">
                  <Col>{ach}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
          {
            title: 'Return Badstock',
            dataIndex: 'return_badstock',
            render: (data: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(data).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: '%',
            dataIndex: 'percentage_return_badstock',
            render: (usage: number) => {
              return (
                <Row justify="end">
                  <Col>{usage}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
        ]
      : []),
    ...(tableCategory === 'Stock'
      ? [
          {
            title: 'Total Stock',
            dataIndex: 'total_value',
            render: (data: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(data).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: 'Stock Standard',
            dataIndex: 'good_value',
            render: (data: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(data).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: 'Bad Stock',
            dataIndex: 'bad_value',
            render: (data: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(data).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: '% Bad Percentage',
            dataIndex: 'bad_percentage',
            render: (ach: number) => {
              return (
                <Row justify="end">
                  <Col>{ach}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
          {
            title: 'Average Sales',
            dataIndex: 'average_sales',
            render: (data: number) => {
              return (
                <Row justify="space-between">
                  <Col>Rp.</Col>
                  <Col>{Number(data).toLocaleString('id')}</Col>
                </Row>
              );
            },
          },
          {
            title: 'Stock Cover',
            dataIndex: 'stock_cover',
            render: (data: number) => {
              return (
                <Row justify="end">
                  <Col>{data}</Col>
                  <Col>%</Col>
                </Row>
              );
            },
          },
        ]
      : []),
    ...(tableCategory === 'Call'
      ? [
          {
            title: 'Total EC',
            dataIndex: 'total_ec',
            align: 'right',
          },
          {
            title: 'Avg EC',
            dataIndex: 'avg_ec',
            align: 'right',
          },
          {
            title: 'Total AO',
            dataIndex: 'total_ao',
            align: 'right',
          },
          {
            title: 'Avg AO',
            dataIndex: 'avg_ao',
            align: 'right',
          },
          ...Array.from(
            {length: selectedPeriode?.daysInMonth() ?? 0},
            (v, i) => i + 1,
          ).map((item: number) => ({
            title: String(item),
            dataIndex: String(item),
            align: 'right',
            width: 60,
          })),
        ]
      : []),
  ];

  let formLoading = salesListLoading || isLoading;

  return (
    <>
      <Card className="mt15">
        <Row align="middle" gutter={[10, 10]}>
          <Col flex={10}>
            <Text className="title">Laporan Salesman</Text>
          </Col>
          <Col flex={1}>
            <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
              <Col>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <div onClick={onModalExportOpened}>
                            <ExportOutlined className="mr5" /> Ekspor
                          </div>
                        ),
                      },
                    ],
                  }}
                  placement="bottomRight"
                  arrow={false}
                >
                  <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card className="mt10">
        <Row gutter={[16, 16]} align="middle">
          <Col span={24} lg={4}>
            {useZone ? (
              <Row align="middle">
                <Col style={{padding: 10}}>
                  <RadarChartOutlined style={{fontSize: 25}} />
                </Col>
                <Col>
                  <Col>Zona</Col>
                  <Col className="txt-blue">
                    <ZoneDropdown
                      value={selectedZone}
                      onChange={setSelectedZone}
                      type="popover"
                      showOptionAllZone
                    />
                  </Col>
                </Col>
              </Row>
            ) : (
              <Row align="middle">
                <Col style={{padding: 10}}>
                  <NodeIndexOutlined style={{fontSize: 25}} />
                </Col>
                <Col>
                  <Col>Cabang</Col>
                  <Col className="txt-blue">
                    <BranchDropdown
                      value={selectedBranch}
                      onChange={setSelectedBranch}
                      type="popover"
                      showOptionAllBranch
                    />
                  </Col>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24} lg={4}>
            <Row align="middle">
              <Col style={{padding: 10}}>
                <ScheduleOutlined style={{fontSize: 25}} />
              </Col>
              <Col>
                <Col>Periode</Col>
                <Col className="txt-blue">
                  <TextDatePicker
                    value={selectedPeriode}
                    onChange={setSelectedPeriode}
                  />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={{span: 4, offset: 0}} xl={{span: 4, offset: 2}}>
            <Col>Target Bulan Ini</Col>
            <Col>
              <b>Rp. {Number(data?.data.target ?? 0).toLocaleString('id')}</b>
            </Col>
          </Col>
          <Col span={24} lg={4} xl={4}>
            <Col>Total Penjualan</Col>
            <Col>
              <b>Rp. {Number(data?.data.sales ?? 0).toLocaleString('id')}</b>
            </Col>
          </Col>
          <Col span={24} lg={4} xl={3}>
            <Col>Achievement</Col>
            <Col className="txt-right">
              <b>{Number(data?.data.percentage ?? 0).toLocaleString('id')} %</b>
            </Col>
          </Col>
          <Col span={24} lg={4} xl={3}>
            <Col>Time Rate</Col>
            <Col className="txt-right">
              <b>{data?.data?.runningLate ?? '0'} %</b>
            </Col>
          </Col>
        </Row>
      </Card>
      <Card className="mt10">
        {tableCategory === 'Call' ? (
          <Row gutter={[16, 16]} align="middle" justify="start">
            <Col span={24} lg={4} xl={4}>
              <Col>Effective Call</Col>
              <Col className={isTablet ? 'txt-right' : ''}>
                <b>
                  {Number(
                    (salesListData?.data as any)?.totalEC ?? 0,
                  ).toLocaleString('id')}
                </b>
              </Col>
            </Col>
            <Col span={24} lg={4} xl={4}>
              <Col>Average EC</Col>
              <Col className={isTablet ? 'txt-right' : ''}>
                <b>
                  {Number(
                    (salesListData?.data as any)?.avgEC ?? 0,
                  ).toLocaleString('id')}
                </b>
              </Col>
            </Col>
            <Col span={24} lg={4} xl={3}>
              <Col>Active Outlet</Col>
              <Col className={isTablet ? 'txt-right' : ''}>
                <b>
                  {Number(
                    (salesListData?.data as any)?.totalAO ?? 0,
                  ).toLocaleString('id')}
                </b>
              </Col>
            </Col>
            <Col span={24} lg={4} xl={3}>
              <Col>Salesman</Col>
              <Col className={isTablet ? 'txt-right' : ''}>
                <b>
                  {Number(
                    (salesListData?.data as any)?.salesman ?? 0,
                  ).toLocaleString('id')}
                </b>
              </Col>
            </Col>
            <Col style={{marginLeft: 'auto'}}>
              <Segmented
                options={['Budget', 'Return', 'Stock', 'Call']}
                onChange={setTableCategory}
                value={tableCategory}
              />
            </Col>
          </Row>
        ) : (
          <Row justify="end">
            <Segmented
              options={['Budget', 'Return', 'Stock', 'Call']}
              onChange={setTableCategory}
              value={tableCategory}
            />
          </Row>
        )}

        <Table
          dataSource={listData}
          columns={column as any}
          loading={formLoading}
          rowKey="id"
          className="mt10"
          {...(isTablet
            ? {scroll: {y: 300, x: tableCategory === 'Call' ? 4000 : 2000}}
            : {scroll: {y: '57vh', x: tableCategory === 'Call' ? 4000 : 2000}})}
          pagination={{
            current: page,
            onChange: setPage,
            total: listTotal,
            showSizeChanger: false,
            showTotal: (total: number, range) => (
              <div className="total-pagination">
                Menampilkan {range[0]} - {range[1]} dari {total}
              </div>
            ),
          }}
        />
      </Card>
      <Modal
        title="Ekspor"
        open={showModalExport}
        onCancel={onModalExportClosed}
        footer={[
          <Button
            key="batal"
            onClick={onModalExportClosed}
            icon={<LeftOutlined />}
          >
            Kembali
          </Button>,
          <Button
            key="submit"
            form="modalExportForm"
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
          >
            Unduh
          </Button>,
        ]}
        {...(isTablet && {width: '80vw'})}
      >
        <Form
          id="modalExportForm"
          form={form}
          onFinish={onExportSubmited}
          layout="vertical"
        >
          <Row gutter={16} className="mt15">
            <Col span={24}>
              <Form.Item
                name="reportType"
                label="Tipe Laporan"
                initialValue="Laporan Effective Call Salesman"
              >
                <Input placeholder="Tipe Laporan" readOnly />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Periode"
                name="period"
                rules={[{required: true, message: 'Periode harus dipilih'}]}
              >
                <DatePicker className="w-100p" picker="month" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
