import {targetSalesRouter} from './sales/target-sales';
import {importSalesRouter} from './sales/importSales';
import {importStockRouter} from './stock/importStock';
import {reportBranchRouter} from './reports/branch-report';
import {reportPrincipalRouter} from './reports/principal-report';
import {reportSalesmanRouter} from './reports/sales-report';
import {userRouter} from './system/users';

export let allRouter = [
  ...importSalesRouter,
  ...importStockRouter,
  ...reportBranchRouter,
  ...reportPrincipalRouter,
  ...reportSalesmanRouter,
  ...userRouter,
  ...targetSalesRouter,
];
