import axios from 'axios';
import {notification} from 'antd';
import * as history from 'history';

import {onLogout} from './auth';
import {decryptData} from './padlock';

import {signatureMerchant} from 'constants/globalConstants';

const history_ = history.createBrowserHistory();
const FetchInterceptor = axios.create({
  timeout: 60000,
});

const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
FetchInterceptor.interceptors.request.use(
  (config) => {
    let activeUser = localStorage.getItem('_rms.mu');
    let token = localStorage.getItem('_rms.mt');

    if (!activeUser && !config.headers[PUBLIC_REQUEST_KEY]) {
      history_.push('/');
      window.location.reload();
    }

    config.headers.Authorization = token && `Bearer ${decryptData(token)}`;
    config.headers.signature = signatureMerchant();
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({message: 'Failed', description: 'Request Error'});
    return Promise.reject(error);
  },
);

// API respone interceptor
FetchInterceptor.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status && error.response.data) {
      if (error.response.status === 401) {
        onLogout();
        notification.error({
          message: 'Sesi Habis',
          description: 'Mohon untuk login kembali!',
        });
      } else {
        let detailMessage =
          error.response.data.message === 'MISSING_REQUIRED_DATA'
            ? error.response.data.data[0].message
            : error.response.data.message === 'PROCESSING_ERROR'
              ? error.response.data.data
                ? error.response.data.data.message
                : error.response.message
              : error.response.data.message === 'UNPROCESSABLE_ENTITY'
                ? error.response.data.data
                  ? error.response.data.data.message
                  : error.response.message
                : error.response.data.message === 'DUPLICATE_DATA'
                  ? error.response.data.data
                    ? error.response.data.data.message
                    : error.response.message
                  : error.response.message;

        notification.error({
          message: 'Terdapat kendala',
          description: error.response.data.message
            ? `${error.response.data.message.replaceAll('_', ' ')}${
                detailMessage ? ` - ${detailMessage}` : ''
              }`
            : error.response.statusText,
        });
        throw new Error(detailMessage);
      }
    } else {
      notification.error({
        message: 'Permintaan Gagal Diproses',
        description: 'Mohon periksa kembali koneksi jaringan Anda!',
      });
    }
    throw new Error();
  },
);

export default FetchInterceptor;
