import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Grid,
  Input,
  Modal,
  Popover,
  Row,
  Table,
  Typography,
} from 'antd';
import {
  CheckSquareFilled,
  ExportOutlined,
  LeftOutlined,
  MoreOutlined,
  NodeIndexOutlined,
  RadarChartOutlined,
  SaveOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import dayjs, {Dayjs} from 'dayjs';

import {ZoneDropdown, TextDatePicker, BranchDropdown} from 'components';

import {useQuery} from 'helpers/fetchingHooks';
import {printDownloadedFile} from 'helpers/printDownloadedFile';
import {useGetUserData} from 'helpers/useGetUserData';
import {countOffset} from 'helpers/globalHelpers';

import type {User, ReportTargetType} from 'types';

const {Text} = Typography;

type UploadReport = {
  sales: boolean;
  stock: boolean;
};

type ReportLog = {
  zone: string;
  branch: string;
  today_status: UploadReport;
};

const limit = 10;

export function BranchReportScenes() {
  let [form] = Form.useForm();
  let breakPoint = Grid.useBreakpoint();
  let isTablet = !breakPoint.lg;
  let {useZone} = useGetUserData();

  let [page, setPage] = useState(1);
  let [showModalExport, setShowModalExport] = useState(false);
  let [selectedZone, setSelectedZone] = useState('');
  let [selectedBranch, setSelectedBranch] = useState('');
  let [selectedPeriode, setSelectedPeriode] =
    useState<Optional<Dayjs>>(dayjs());
  let [sortDailyStatus, setSortDailyStatus] = useState<'asc' | 'desc' | null>(
    null,
  );

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [selectedZone, selectedBranch, selectedPeriode]);

  let {data, isLoading} = useQuery<ResultDetail<ReportTargetType>>({
    path: '/rms/dashboard/target',
    queryKey: [
      'dashboard-target',
      {selectedPeriode, selectedZone, selectedBranch},
    ],
    config: {
      params: {
        ...(selectedPeriode
          ? {date: selectedPeriode.format('YYYY-MM-DD')}
          : {}),
        ...(selectedZone && selectedZone !== 'all' ? {zone: selectedZone} : {}),
        ...(selectedBranch && selectedBranch !== 'all'
          ? {branchId: selectedBranch}
          : {}),
      },
    },
    configRQ: {
      queryKey: [
        'dashboard-target',
        {selectedPeriode, selectedZone, selectedBranch},
      ],
      enabled: !!selectedZone && !!selectedPeriode,
    },
  });

  let {data: branchReportData, isLoading: salesListLoading} = useQuery<
    ResultPagination<ReportLog>
  >({
    path: `/rms/dashboard/report/log`,
    queryKey: [
      'dashboard-report-log',
      {selectedPeriode, selectedZone, sortDailyStatus, selectedBranch, page},
    ],
    config: {
      params: {
        limit,
        offset: countOffset(page, limit),
        ...(selectedPeriode
          ? {date: selectedPeriode.format('YYYY-MM-DD')}
          : {}),
        ...(selectedZone && selectedZone !== 'all' ? {zone: selectedZone} : {}),
        ...(selectedBranch && selectedBranch !== 'all'
          ? {branchId: selectedBranch}
          : {}),
        ...(sortDailyStatus ? {orderToday: sortDailyStatus} : {}),
      },
    },
    configRQ: {
      queryKey: [
        'dashboard-report-log',
        {selectedPeriode, selectedZone, sortDailyStatus, selectedBranch, page},
      ],
      enabled: !!selectedZone && !!selectedPeriode,
    },
  });

  let onModalExportClosed = useCallback(() => setShowModalExport(false), []);
  let onModalExportOpened = useCallback(() => setShowModalExport(true), []);
  let onExportSubmited = useCallback(async () => {
    let formValue;
    try {
      formValue = await form.validateFields();
    } catch (error) {}

    try {
      let date = dayjs(formValue?.period).format('YYYY-MM-DD');

      await printDownloadedFile({
        path: '/rms/dashboard/report',
        params: {
          date,
          reportType: 'Laporan Penjualan Harian',
        },
        type: 'xlsx',
        nameFile: `laporan-penjualan-harian-${date}`,
      });

      setShowModalExport(false);
    } catch (error) {}
  }, [form]);

  let listData = useMemo(() => {
    let rawData = branchReportData?.data?.rows ?? [];

    return rawData.map((item, index) => ({id: index, ...item}));
  }, [branchReportData]);
  let listTotal = useMemo(
    () => branchReportData?.data.count ?? 0,
    [branchReportData],
  );

  const column = [
    {
      title: 'No',
      width: 55,
      render: (_: string, __: User, index: number) =>
        index + countOffset(page, limit) + 1,
    },
    {
      title: 'Zona',
      dataIndex: 'zone',
    },
    {
      title: 'Cabang',
      dataIndex: 'branch',
    },
    {
      title: 'Update Hari Ini',
      dataIndex: 'today_status',
      sorter: () => {},
      render: (data: UploadReport) => {
        if (data) {
          return (
            <Row justify="center">
              <Popover
                content={
                  <Col>
                    <Col>
                      <CheckSquareFilled
                        className={data.sales ? 'txt-green' : 'txt-gray'}
                      />{' '}
                      Laporan Sales
                    </Col>
                    <Col>
                      <CheckSquareFilled
                        className={data.stock ? 'txt-green' : 'txt-gray'}
                      />{' '}
                      Laporan Stok
                    </Col>
                  </Col>
                }
              >
                <CheckSquareFilled
                  className={
                    data.stock && data.sales ? 'txt-green' : 'txt-gray'
                  }
                />
              </Popover>
            </Row>
          );
        }

        return null;
      },
    },
    ...Array.from(
      {length: selectedPeriode?.daysInMonth() ?? 0},
      (_, i) => i + 1,
    ).map((item: number) => ({
      title: String(item),
      dataIndex: String(item),
      align: 'center',
      width: 60,
      render: (data: UploadReport) => {
        if (data) {
          return (
            <Row justify="center">
              <Popover
                content={
                  <Col>
                    <Col>
                      <CheckSquareFilled
                        className={data.sales ? 'txt-green' : 'txt-gray'}
                      />{' '}
                      Laporan Sales
                    </Col>
                    <Col>
                      <CheckSquareFilled
                        className={data.stock ? 'txt-green' : 'txt-gray'}
                      />{' '}
                      Laporan Stok
                    </Col>
                  </Col>
                }
              >
                <CheckSquareFilled
                  className={
                    data.stock && data.sales ? 'txt-green' : 'txt-gray'
                  }
                />
              </Popover>
            </Row>
          );
        }

        return null;
      },
    })),
  ];

  let formLoading = salesListLoading || isLoading;

  return (
    <>
      <Card className="mt15">
        <Row align="middle" gutter={[10, 10]}>
          <Col flex={10}>
            <Text className="title">Laporan Cabang</Text>
          </Col>
          <Col flex={1}>
            <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
              <Col>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <div onClick={onModalExportOpened}>
                            <ExportOutlined className="mr5" /> Ekspor
                          </div>
                        ),
                      },
                    ],
                  }}
                  placement="bottomRight"
                  arrow={false}
                >
                  <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card className="mt10">
        <Row gutter={[16, 16]} align="middle">
          <Col span={24} lg={4}>
            {useZone ? (
              <Row align="middle">
                <Col style={{padding: 10}}>
                  <RadarChartOutlined style={{fontSize: 25}} />
                </Col>
                <Col>
                  <Col>Zona</Col>
                  <Col className="txt-blue">
                    <ZoneDropdown
                      value={selectedZone}
                      onChange={setSelectedZone}
                      type="popover"
                      showOptionAllZone
                    />
                  </Col>
                </Col>
              </Row>
            ) : (
              <Row align="middle">
                <Col style={{padding: 10}}>
                  <NodeIndexOutlined style={{fontSize: 25}} />
                </Col>
                <Col>
                  <Col>Cabang</Col>
                  <Col className="txt-blue">
                    <BranchDropdown
                      value={selectedBranch}
                      onChange={setSelectedBranch}
                      type="popover"
                      showOptionAllBranch
                    />
                  </Col>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24} lg={4}>
            <Row align="middle">
              <Col style={{padding: 10}}>
                <ScheduleOutlined style={{fontSize: 25}} />
              </Col>
              <Col>
                <Col>Periode</Col>
                <Col className="txt-blue">
                  <TextDatePicker
                    value={selectedPeriode}
                    onChange={setSelectedPeriode}
                  />
                </Col>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={{span: 4, offset: 0}} xl={{span: 4, offset: 2}}>
            <Col>Target Bulan Ini</Col>
            <Col>
              <b>Rp. {Number(data?.data.target ?? 0).toLocaleString('id')}</b>
            </Col>
          </Col>
          <Col span={24} lg={4} xl={4}>
            <Col>Total Penjualan</Col>
            <Col>
              <b>Rp. {Number(data?.data.sales ?? 0).toLocaleString('id')}</b>
            </Col>
          </Col>
          <Col span={24} lg={4} xl={3}>
            <Col>Achievement</Col>
            <Col className="txt-right">
              <b>{Number(data?.data.percentage ?? 0).toLocaleString('id')} %</b>
            </Col>
          </Col>
          <Col span={24} lg={4} xl={3}>
            <Col>Time Rate</Col>
            <Col className="txt-right">
              <b>{data?.data?.runningLate ?? '0'} %</b>
            </Col>
          </Col>
        </Row>
      </Card>
      {selectedZone && selectedPeriode && (
        <Card className="mt10">
          <Table
            dataSource={listData}
            columns={column as any}
            loading={formLoading}
            rowKey="id"
            className="mt10"
            {...(isTablet
              ? {scroll: {y: 300, x: 2500}}
              : {scroll: {y: '57vh', x: 2500}})}
            onChange={(_, __, sorter: any) => {
              if (sorter.field === 'today_status') {
                let sortInfo = sorter.order === 'ascend' ? 'asc' : 'desc';
                setSortDailyStatus(sortInfo as 'asc' | 'desc');
              }
            }}
            pagination={{
              current: page,
              onChange: setPage,
              total: listTotal,
              showSizeChanger: false,
              showTotal: (total: number, range) => (
                <div className="total-pagination">
                  Menampilkan {range[0]} - {range[1]} dari {total}
                </div>
              ),
            }}
          />
        </Card>
      )}
      <Modal
        title="Ekspor"
        open={showModalExport}
        onCancel={onModalExportClosed}
        footer={[
          <Button
            key="batal"
            onClick={onModalExportClosed}
            icon={<LeftOutlined />}
          >
            Kembali
          </Button>,
          <Button
            key="submit"
            form="modalExportForm"
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
          >
            Unduh
          </Button>,
        ]}
        {...(isTablet && {width: '80vw'})}
      >
        <Form
          id="modalExportForm"
          form={form}
          onFinish={onExportSubmited}
          layout="vertical"
        >
          <Row gutter={16} className="mt15">
            <Col span={24}>
              <Form.Item
                name="reportType"
                label="Tipe Laporan"
                initialValue="Laporan Penjualan Harian"
              >
                <Input placeholder="Tipe Laporan" readOnly />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Periode"
                name="period"
                rules={[{required: true, message: 'Periode harus dipilih'}]}
              >
                <DatePicker className="w-100p" picker="month" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
