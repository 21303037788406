import {useCallback} from 'react';
import {Avatar, Dropdown} from 'antd';
import {UserOutlined, LogoutOutlined} from '@ant-design/icons';

import {onLogout} from 'helpers/auth';
import {useGetUserData} from 'helpers/useGetUserData';

import {BLUE} from 'constants/colors';
import './menu.css';

export default function NavProfile() {
  let {employeeName, role} = useGetUserData();

  let onSignOutPressed = useCallback(() => {
    onLogout();
    window.location.reload();
  }, []);

  let firstCharacter = employeeName ? (
    employeeName.substring(0, 1).toUpperCase()
  ) : (
    <UserOutlined />
  );

  let profileMenu = (
    <div className="nav-profile">
      <div className="nav-profile-header">
        <div className="nav-header-content">
          <Avatar size={40} style={styles.avatar}>
            {firstCharacter}
          </Avatar>
          <div className="pl10">
            <b>{employeeName ?? '-'}</b>
            <div>{String(role?.name ?? '-').toLowerCase()}</div>
          </div>
        </div>
      </div>
      <div className="nav-profile-body" onClick={onSignOutPressed}>
        <span>
          <LogoutOutlined className="mr10" />
          <span>Sign Out</span>
        </span>
      </div>
    </div>
  );

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => profileMenu}
      placement="bottomRight"
      className="nav-profile-dropdown"
    >
      <Avatar size={32} style={styles.avatar}>
        {firstCharacter}
      </Avatar>
    </Dropdown>
  );
}

const styles = {
  avatar: {backgroundColor: BLUE},
};
