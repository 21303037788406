export function oneDimensionalMenu(
  data: Array<any>,
  withoutParent: boolean = false,
): Array<string> {
  let accessMenus = data.reduce((prev: Array<string>, cur: any) => {
    if (cur?.subMenu?.length > 0) {
      let data1 =
        typeof cur.subMenu[0] === 'string'
          ? cur.subMenu
          : oneDimensionalMenu(cur.subMenu, withoutParent);
      if (withoutParent) {
        return [...prev, ...data1];
      } else {
        return [...prev, cur.menu, ...data1];
      }
    }

    if (cur.menu) {
      return [...prev, cur.menu];
    }

    return prev;
  }, []);
  return accessMenus;
}

export function checkAvaibilityMenu<T>(
  menu: Array<any>,
  listMenu?: Array<string>,
): Array<T> {
  let menu_ = menu.reduce((prev: Array<T>, cur: MenuStructureType) => {
    if (cur.children) {
      let anotherMenu = checkAvaibilityMenu(cur.children, listMenu);

      if (anotherMenu.length > 0) {
        let {backendKey, newKey, ...anotherProps} = cur;
        return [...prev, {...anotherProps, children: anotherMenu}];
      }

      return prev;
    }

    if (listMenu?.some((item) => cur.backendKey === item)) {
      let {backendKey, newKey, ...anotherProps} = cur;
      return [...prev, anotherProps];
    }

    return prev;
  }, []);

  return menu_;
}

export function countOffset(page: number, limit: number) {
  return page * limit - limit;
}
