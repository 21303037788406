import {Navigate} from 'react-router-dom';

import {SalesReportScenes} from './SalesReportScenes';

export const FEATURE_PATH = '/report/salesman';

export const reportSalesmanRouter = [
  {
    path: FEATURE_PATH,
    backendKey: 'sidenav.report.salesman',
    element: <SalesReportScenes />,
  },
  {
    path: `${FEATURE_PATH}/*`,
    backendKey: 'sidenav.report.salesman',
    element: <Navigate to={FEATURE_PATH} replace />,
  },
];
