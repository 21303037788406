import {CSSProperties} from 'react';
import {Form, Input, Button, Row, Col, Image} from 'antd';
import {MailOutlined, LockOutlined} from '@ant-design/icons';

import {useMutation} from 'helpers/fetchingHooks';
import {oneDimensionalMenu} from 'helpers/globalHelpers';
import {encryptData} from 'helpers/padlock';

function AuthenticationScene() {
  let [form] = Form.useForm();

  let {isPending, mutate: onLogin} = useMutation(
    {
      path: '/rms/auth',
      method: 'post',
      config: {
        headers: {
          'public-request': 'true',
        },
      },
    },
    {
      onSuccess: (response) => {
        let stateUser = {
          token: response?.data?.token,
          employee: response?.data?.employee ?? {},
          role: response?.data?.role ?? {},
          oneDimensionalAccessMenus: oneDimensionalMenu(
            response?.data?.accessMenu ?? [],
          ),
          branches: response?.data?.branches ?? [],
        };
        localStorage.setItem('_rms.mu', encryptData(JSON.stringify(stateUser)));
        localStorage.setItem('_rms.mt', encryptData(response?.data?.token));
        window.location.reload();
      },
    },
  );

  return (
    <div>
      <Row align="middle" justify="center" style={styles.rowWrapper}>
        <Col span={6} xs={20} sm={18} md={10} lg={8} xl={6}>
          <div style={styles.loginContainer}>
            <div style={styles.logoWrapper}>
              <Image
                width={150}
                src="/logo.png"
                alt="login-logo"
                preview={false}
              />
            </div>
            <Form
              name="login-form"
              layout="vertical"
              onFinish={onLogin}
              form={form}
            >
              <Form.Item
                label="Username"
                name="email"
                rules={[{required: true, message: 'Username diperlukan !'}]}
                className="mb-15"
              >
                <Input
                  prefix={<MailOutlined className="text-primary" />}
                  placeholder="Masukan email yang terdaftar"
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{required: true, message: 'Password diperlukan !'}]}
              >
                <Input.Password
                  prefix={<LockOutlined className="text-primary" />}
                  placeholder="Masukan kata sandi"
                />
              </Form.Item>
              <Form.Item style={styles.buttonWrapper}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isPending}
                >
                  Masuk
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const styles: ObjectKey<CSSProperties> = {
  rowWrapper: {height: '100vh'},
  logoWrapper: {textAlign: 'center', marginBottom: 40},
  buttonWrapper: {marginTop: 30},
  loginContainer: {padding: 24},
};

export default AuthenticationScene;
