import {useCallback, useState} from 'react';
import {App, Button, Col, Form, Modal, Row, Spin, Upload} from 'antd';
import {
  CloudUploadOutlined,
  LeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import FetchInterceptor from 'helpers/fetchInterceptor';

type Props = {
  onClose?: () => void;
  refetch?: () => void;
  isVisible?: boolean;
};

export default function ModalImportUser(props: Props) {
  let {onClose, refetch, isVisible = false} = props;
  let {message} = App.useApp();

  let [loading, setLoading] = useState(false);
  let [file, setFile] = useState<any[]>([]);

  let onSubmit = useCallback(async () => {
    if (file.length) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append('file', file[0] as Blob);
        await FetchInterceptor.post('/rms/employee/import', formData);
      } catch (error) {}
      setLoading(false);
      onClose && onClose();
      refetch && refetch();
    } else {
      message.warning('File tidak boleh kosong');
    }
  }, [file, message, onClose, refetch]);

  return (
    <Modal
      title="Import Pengguna"
      open={isVisible}
      onCancel={onClose}
      footer={
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button loading={loading} onClick={onClose} icon={<LeftOutlined />}>
              Kembali
            </Button>
          </Col>
          <Col>
            <Button
              loading={loading}
              onClick={onSubmit}
              type="primary"
              icon={<SaveOutlined />}
            >
              Simpan
            </Button>
          </Col>
        </Row>
      }
    >
      <Spin size="large" spinning={loading} className="mv15">
        <Form layout="vertical">
          <Upload.Dragger
            name="file"
            accept=".csv .xls .xlsx"
            fileList={file}
            multiple={false}
            beforeUpload={() => false}
            onRemove={() => setFile([])}
            onChange={({file}) => file.name && setFile([file])}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Pilih file atau letakkan file-mu di sini
            </p>
            <p className="ant-upload-hint">Jenis file hanya berupa excel</p>
            <Button type="primary">Pilih File</Button>
          </Upload.Dragger>
        </Form>
      </Spin>
    </Modal>
  );
}
