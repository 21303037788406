import CryptoJS from 'crypto-js';

const SALT = process.env.REACT_APP_SALT ?? '';

export function encryptData(data: string): string {
  return CryptoJS.AES.encrypt(data, SALT).toString();
}

export function decryptData(data: string) {
  let hash = CryptoJS.AES.decrypt(data, SALT);

  return hash.toString(CryptoJS.enc.Utf8);
}
