import {Navigate} from 'react-router-dom';

import ImportStockScenes from './ImportStockScenes';

export const FEATURE_PATH = '/stock/import-stock';

export const importStockRouter = [
  {
    path: FEATURE_PATH,
    backendKey: 'sidenav.stock.importstock',
    element: <ImportStockScenes />,
  },
  {
    path: `${FEATURE_PATH}/*`,
    backendKey: 'sidenav.stock.importstock',
    element: <Navigate to={FEATURE_PATH} replace />,
  },
];
