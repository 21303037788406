import {useCallback, useState} from 'react';
import {
  App,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popover,
  Result,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import {
  CloudUploadOutlined,
  ImportOutlined,
  LeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import {OwnedBranchDropdown} from 'components';

import FetchInterceptor from 'helpers/fetchInterceptor';

const {Text} = Typography;

let IMPORT_SOURCE = [
  {
    value: 'INTEGRATE',
    label: 'Integrasi',
  },
  {
    value: 'NEW INTEGRATE',
    label: 'Integrasi Baru',
  },
  {
    value: 'ND6',
    label: 'ND6',
  },
];

export function ImportSalesScenes() {
  let [form] = Form.useForm();
  let {message} = App.useApp();

  let selectedSource = Form.useWatch('source', form);

  let [isSuccess, setIsSuccess] = useState(false);
  let [showImportOption, setShowImportOption] = useState(false);
  let [loading, setLoading] = useState(false);
  let [file, setFile] = useState<any[]>([]);

  let onBackPressed = useCallback(() => {
    setIsSuccess(false);
    form.resetFields();
  }, [form]);

  let onImportSourcePressed = useCallback(
    (type: string) => () => {
      form.setFieldsValue({source: type});
      if (isSuccess) {
        setIsSuccess(false);
      }
      setShowImportOption(false);
    },
    [form, isSuccess],
  );

  let onRemoveDragger = useCallback(() => {
    setFile([]);
  }, []);

  let onImportSubmit = useCallback(async () => {
    let formValue;
    try {
      formValue = await form.validateFields();
    } catch (error) {}

    if (file.length) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append('file', file[0]);
        formData.append('date', `${formValue.periode.format('YYYY-MM')}-01`);
        formData.append('source', formValue.source);
        formData.append('branchId', formValue.branchId);

        await FetchInterceptor.post('/rms/sales/import', formData);
        form.resetFields();
        setFile([]);
        setIsSuccess(true);
      } catch (error) {}
      setLoading(false);
    } else {
      message.warning('File tidak boleh kosong');
    }
  }, [file, form, message]);

  let formLoading = loading;

  return (
    <>
      <Card className="mt15">
        <Row align="middle" gutter={[10, 10]}>
          <Col flex={10}>
            <Text className="title">Impor Penjualan</Text>
          </Col>
          <Col flex={1}>
            <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
              <Col>
                <Popover
                  content={
                    <Form layout="vertical" form={form}>
                      <Col span={24}>
                        <Button
                          block
                          type="text"
                          onClick={onImportSourcePressed('INTEGRATE')}
                        >
                          Integrasi
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          block
                          type="text"
                          onClick={onImportSourcePressed('NEW INTEGRATE')}
                        >
                          Integrasi Baru
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          block
                          type="text"
                          onClick={onImportSourcePressed('ND6')}
                        >
                          ND6
                        </Button>
                      </Col>
                    </Form>
                  }
                  placement="bottomRight"
                  arrow={false}
                  title={
                    <>
                      <Row>
                        <ImportOutlined className="mr5" />
                        Impor
                      </Row>
                      <Divider className="mv5" />
                    </>
                  }
                  trigger="click"
                  open={showImportOption}
                  onOpenChange={() => {
                    setShowImportOption(!showImportOption);
                  }}
                >
                  <Button icon={<ImportOutlined />} loading={formLoading} />
                </Popover>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form form={form} layout="vertical" disabled={formLoading}>
          <Form.Item name="source" hidden>
            <Input />
          </Form.Item>
          {selectedSource && (
            <>
              <Divider />
              <Spin size="large" spinning={loading}>
                <Row gutter={16} align="bottom">
                  <Col span={24} md={8} lg={6}>
                    <Form.Item
                      label="Periode"
                      name="periode"
                      rules={[
                        {required: true, message: 'Periode harus dipilih'},
                      ]}
                    >
                      <DatePicker className="w-100p" picker="month" />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8} lg={6}>
                    <Form.Item
                      label="Cabang"
                      name="branchId"
                      rules={[
                        {required: true, message: 'Cabang harus dipilih'},
                      ]}
                    >
                      <OwnedBranchDropdown
                        showSearch
                        filterOption={(input, option) =>
                          String(option?.children ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8} lg={6}>
                    <Form.Item
                      label="Sumber"
                      name="source"
                      rules={[
                        {required: true, message: 'Sumber harus dipilih'},
                      ]}
                    >
                      <Select placeholder="Sumber" disabled>
                        {IMPORT_SOURCE.map((item) => (
                          <Select.Option
                            value={item.value}
                            key={`${item.value}-import-source-dropdown`}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Dragger
                  name="file"
                  accept=".csv .xls .xlsx"
                  fileList={file}
                  multiple={false}
                  beforeUpload={() => false}
                  onRemove={onRemoveDragger}
                  onChange={({file}) => file.name && setFile([file])}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Pilih file atau letakkan file-mu di sini
                  </p>
                  <p className="ant-upload-hint">
                    Jenis file hanya berupa excel
                  </p>
                  <Button type="primary">Pilih File</Button>
                </Dragger>
                <Row gutter={[16, 16]} className="mt15" justify="end">
                  <Col span={24} md={4} lg={3}>
                    <Button
                      icon={<LeftOutlined />}
                      block
                      onClick={onBackPressed}
                    >
                      Kembali
                    </Button>
                  </Col>
                  <Col span={24} md={4} lg={3}>
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      onClick={onImportSubmit}
                      block
                    >
                      Unggah
                    </Button>
                  </Col>
                </Row>
              </Spin>
            </>
          )}
          {isSuccess && (
            <>
              <Divider />
              <Result
                status="success"
                title="Berhasil"
                subTitle="Data Penjualan Berhasil diimport ke dalam sistem."
                extra={[
                  <Button type="primary" key="done" onClick={onBackPressed}>
                    Selesai
                  </Button>,
                ]}
              />
            </>
          )}
        </Form>
      </Card>
    </>
  );
}
