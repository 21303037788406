import {Calendar, Popover} from 'antd';
import {useCallback, useState} from 'react';
import {Dayjs} from 'dayjs';

type Props = {
  value?: Optional<Dayjs>;
  onChange?: (value: Dayjs) => void;
};

export function TextDatePicker(props: Props) {
  let {onChange, value} = props;

  let [showFilter, setShowFilter] = useState(false);

  let onOptionPressed = useCallback(
    (value: Dayjs) => {
      onChange && onChange(value);
      setShowFilter(false);
    },
    [onChange],
  );

  return (
    <Popover
      content={
        <div style={styles.calendarWrapper}>
          <Calendar fullscreen={false} mode="year" onChange={onOptionPressed} />
        </div>
      }
      placement="bottomLeft"
      arrow={false}
      trigger="click"
      open={showFilter}
      onOpenChange={() => {
        setShowFilter(!showFilter);
      }}
    >
      <b style={styles.label}>
        {value ? value.format('MMMM YYYY') : 'Pilih Bulan'}
      </b>
    </Popover>
  );
}

const styles = {
  calendarWrapper: {width: 300},
  label: {cursor: 'pointer'},
};
