import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  App,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Grid,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FilterOutlined,
  ImportOutlined,
  MailOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {AlignType} from 'rc-table/lib/interface';

import {OwnedBranchDropdown, RoleDropdown} from 'components';
import ModalImportUser from './components/ModalImportUser';

import {useQuery} from 'helpers/fetchingHooks';
import FetchInterceptor from 'helpers/fetchInterceptor';

import {FEATURE_PATH} from '.';
import type {User} from 'types';

const {Text} = Typography;

export function UserListScenes() {
  let [form] = Form.useForm();
  let {message} = App.useApp();
  let navigate = useNavigate();
  let breakPoint = Grid.useBreakpoint();
  let isTablet = !breakPoint.lg;

  let branchId = Form.useWatch('branchId', form);
  let roleId = Form.useWatch('roleId', form);
  let status = Form.useWatch('status', form);

  let [showFilter, setShowFilter] = useState(false);
  let [showImportModal, setShowImportModal] = useState(false);
  let [deletedLoading, setDeletedLoading] = useState(false);

  let [searchValue, setSearchValue] = useState('');

  let {data, isLoading, refetch} = useQuery<Result<User>>({
    path: '/rms/employee',
    queryKey: ['user-list-scenes', {searchValue, branchId, roleId, status}],
    config: {
      params: {
        ...(searchValue ? {search: searchValue} : {}),
        ...(branchId ? {branchId} : {}),
        ...(roleId === 'all' ? {} : {roleId}),
        ...(status !== 'all' ? {status} : {}),
      },
    },
  });

  let userList = useMemo(() => data?.data ?? [], [data]);

  let onFilterClear = useCallback(() => {
    setSearchValue('');
    form.resetFields();
  }, [form]);

  let onAddPressed = useCallback(() => {
    navigate(`${FEATURE_PATH}/add`);
  }, [navigate]);

  let onDetailPressed = useCallback(
    (mode: 'view' | 'edit', id: string) => () => {
      navigate(`${FEATURE_PATH}/${mode}/${id}`);
    },
    [navigate],
  );

  let onImportPressed = useCallback(() => {
    setShowImportModal(!showImportModal);
  }, [showImportModal, setShowImportModal]);

  let onDeletePressed = useCallback(
    (id: string) => () => {
      Modal.confirm({
        title: 'Konfirmasi',
        icon: <ExclamationCircleOutlined />,
        content: 'Apakah anda yakin ingin menghapus data ini?',
        onOk: async () => {
          setDeletedLoading(true);
          try {
            await FetchInterceptor.delete(`/rms/employee/${id}`);
            refetch();
            message.success('Data berhasil dihapus!');
          } catch (error) {
            message.error(`Terjadi kesalahan, ${error}`);
          } finally {
            setDeletedLoading(false);
          }
        },
      });
    },
    [message, refetch],
  );

  const column = [
    {
      title: 'No',
      width: 55,
      render: (_: string, __: User, index: number) => index + 1,
    },
    {
      title: 'Nama',
      render: (data: User) => (
        <Col>
          <Col>{data.name ?? '-'}</Col>
          <Row justify="space-between">
            <Col>
              <small className="txt-blue">
                <MailOutlined /> <span>{data.email ?? '-'}</span>
              </small>
            </Col>
            <Col>
              <Tag color={data?.is_active ? 'green' : 'red'}>
                {data?.is_active ? 'Aktif' : 'Inaktif'}
              </Tag>
            </Col>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Role',
      render: (data: User) => data?.role?.name ?? '-',
    },
    {
      title: 'Cabang',
      render: (data: User) => {
        return data?.branch?.length >= 1 ? (data?.branch[0]?.name ?? '-') : '-';
      },
    },
    {
      title: '',
      width: 70,
      align: 'center' as AlignType,
      render: (data: User) => (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <div onClick={onDetailPressed('view', data?.id ?? '')}>
                    <EyeOutlined className="mr5" />
                    Lihat
                  </div>
                ),
              },
              {
                key: '2',
                label: (
                  <div onClick={onDetailPressed('edit', data?.id ?? '')}>
                    <EditOutlined className="mr5" />
                    Ubah
                  </div>
                ),
              },
              {
                key: '3',
                label: (
                  <div
                    className="txt-red"
                    onClick={onDeletePressed(data?.id ?? '')}
                  >
                    <DeleteOutlined className="mr5" />
                    Hapus
                  </div>
                ),
              },
            ],
          }}
          placement="bottomRight"
          arrow={false}
        >
          <Button type="text" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  let formLoading = isLoading || deletedLoading;

  return (
    <Card className="mt15">
      <Row align="middle" gutter={[10, 10]} className="row-card-container">
        <Col flex={10}>
          <Text className="title">Pengguna</Text>
        </Col>
        <Col flex={1}>
          <Row gutter={[8, 8]} justify="end" align="middle" className="pt10">
            <Col xs={24} sm={16}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Cari..."
                allowClear
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </Col>
            <Col>
              <Button icon={<PlusOutlined />} onClick={onAddPressed} />
            </Col>
            <Col>
              <Button icon={<ImportOutlined />} onClick={onImportPressed} />
            </Col>
            <Col>
              <Popover
                content={
                  <Form layout="vertical" form={form} disabled={formLoading}>
                    <Row gutter={16} align="middle" justify="space-between">
                      <Col span={24}>
                        <Form.Item label="Cabang" name="branchId">
                          <OwnedBranchDropdown />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Hak Akses"
                          name="roleId"
                          initialValue="all"
                        >
                          <RoleDropdown />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Status"
                          name="status"
                          initialValue="all"
                        >
                          <Select
                            className="inv-select"
                            optionLabelProp="label"
                          >
                            <Select.Option value="all" label="Semua Status">
                              Semua Status
                            </Select.Option>
                            <Select.Option value={true} label="Aktif">
                              Aktif
                            </Select.Option>
                            <Select.Option value={false} label="Nonaktif">
                              Nonaktif
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="divider-filter-action" />
                    <Row justify="end" gutter={10}>
                      <Col>
                        <Button onClick={onFilterClear}>Bersih</Button>
                      </Col>
                      <Col>
                        <Button type="primary">Terapkan</Button>
                      </Col>
                    </Row>
                  </Form>
                }
                placement="bottomRight"
                arrow={false}
                title={
                  <>
                    <Row>
                      <FilterOutlined className="mr5" />
                      Filter
                    </Row>
                    <Divider className="mv5" />
                  </>
                }
                trigger="click"
                open={showFilter}
                onOpenChange={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <Button icon={<FilterOutlined />} />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        dataSource={userList}
        columns={column}
        loading={formLoading}
        rowKey="id"
        {...(isTablet ? {scroll: {y: 300, x: 2000}} : {scroll: {y: '57vh'}})}
      />

      <ModalImportUser
        onClose={onImportPressed}
        isVisible={showImportModal}
        refetch={refetch}
      />
    </Card>
  );
}
