import {decryptData} from './padlock';

export function useGetUserData() {
  let selectedBranchID = sessionStorage.getItem('_rms.msb');
  let activeUserStr = localStorage.getItem('_rms.mu');
  let activeUser: UserState = activeUserStr
    ? JSON.parse(decryptData(activeUserStr))
    : {};

  let {
    employee,
    oneDimensionalAccessMenus = [],
    branches = [],
    role,
  } = activeUser;

  let useZone = role?.slug === 'ho' || role?.slug === 'hoc';

  let branchID = branches.length >= 1 ? branches[0].id : null;
  let isNavBarShow = oneDimensionalAccessMenus?.some(
    (item) => item === 'navbar.branch',
  );

  return {
    branchId: selectedBranchID
      ? selectedBranchID
      : isNavBarShow
        ? null
        : branchID,
    branches,
    role,
    employeeName: employee?.name,
    employeeId: employee?.id,
    employeeBranchId: branchID,
    token: activeUser?.token,
    isNavBarShow,
    accessMenus: oneDimensionalAccessMenus,
    useZone: useZone ? true : false,
  };
}
