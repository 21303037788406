import {useCallback, useMemo, useState} from 'react';
import {Col, Form, Input, Popover, Select} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {SelectProps} from 'antd/lib';
import {BaseOptionType} from 'antd/es/select';

import {useGetUserData} from 'helpers/useGetUserData';

type Props = SelectProps & {
  value?: string;
  showOptionAllBranch?: boolean;
  onChange?: (value: string) => void;
  type?: 'dropdown' | 'popover';
};

export function BranchDropdown(props: Props) {
  let {onChange, value, type = 'dropdown', showOptionAllBranch = false} = props;
  let [form] = Form.useForm();
  let {branches} = useGetUserData();

  let branchList = useMemo(() => {
    let data = branches.map((item) => ({
      value: item.id ?? '',
      label: item.name ?? '',
    }));

    if (showOptionAllBranch) {
      return [{value: 'all', label: 'Semua Cabang'}, ...data];
    } else {
      return data;
    }
  }, [branches, showOptionAllBranch]);

  let searchValue = Form.useWatch('search', form);

  let [showFilter, setShowFilter] = useState(false);

  let onOptionPressed = useCallback(
    (value: string) => () => {
      onChange && onChange(value);
      setShowFilter(false);
      form.resetFields();
    },
    [form, onChange],
  );

  let filterSort = useCallback(
    (optionA: BaseOptionType, optionB: BaseOptionType) =>
      String(optionA?.label ?? '')
        .toLowerCase()
        .localeCompare(String(optionB?.label ?? '').toLowerCase()),
    [],
  );

  if (type === 'dropdown') {
    return (
      <Select
        placeholder="Masukan Cabang"
        filterSort={filterSort}
        optionFilterProp="label"
        showSearch
        {...props}
      >
        {branchList.map((item) => (
          <Select.Option
            value={item.value}
            key={`${item.value}-branch-dropdown`}
            label={item.label}
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <Popover
      content={
        <Form layout="vertical" form={form}>
          <Col>
            <Form.Item name="search" className="mb0">
              <Input
                prefix={<SearchOutlined />}
                placeholder="Cari..."
                allowClear
              />
            </Form.Item>
            <div style={{paddingTop: 5, overflow: 'auto', maxHeight: 250}}>
              {branchList.reduce((prev: any, cur, index) => {
                if (
                  searchValue &&
                  !cur.label.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                  return prev;
                }

                return [
                  ...prev,
                  <div
                    key={`branch-dropdown-${index}`}
                    className="text-option"
                    onClick={onOptionPressed(cur.value)}
                  >
                    {cur.label}
                  </div>,
                ];
              }, [])}
            </div>
          </Col>
        </Form>
      }
      placement="bottomRight"
      arrow={false}
      trigger="click"
      open={showFilter}
      onOpenChange={() => {
        setShowFilter(!showFilter);
      }}
    >
      <b style={{cursor: 'pointer'}}>
        {value
          ? branchList.find((item) => item.value === value)?.label
          : 'Pilih Cabang'}
      </b>
    </Popover>
  );
}

// .ant-popover-inner {
//     max-height: 300px;
//     overflow: hidden;
//   }
