import FetchInterceptor from './fetchInterceptor';

type Props = {
  path: string;
  params?: ObjectKey;
  type?: 'pdf' | 'xlsx';
  nameFile?: string;
};

let TYPE_FILE = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export async function printDownloadedFile(props: Props) {
  let {path, params, type = 'pdf', nameFile} = props;

  try {
    let data: Blob = await FetchInterceptor.get(path, {
      params,
      responseType: 'blob',
    });
    let file = new Blob([data], {type: TYPE_FILE[type]});
    let fileURL = URL.createObjectURL(file);

    let link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', `${nameFile}.${type}`);
    document.body.appendChild(link);
    link.click();

    // TODO : Check in safari
    // window.open(fileURL);
  } catch (error: any) {
    return new Error(`Gagal mengambil data : ${error.message}`);
  }
}
